<template>
<div>
  <div v-if="pdfdata && !limitedPreview && numPages > 1" class="d-flex justify-content-between">
    <Button :deactivated="page <= 1" @click="page > 1 ? page-- : 1">
    {{ $t('Back') }}
    <icon type="angle-left" class="left chevron icon"/>
  </Button>
    <a class="ui active item mt-2">
      {{ page }} / {{ numPages ? numPages : '∞' }}
    </a>
    <Button :deactivated="page === numPages" @click="page < numPages ? page++ : 1">
      {{ $t('Forward') }}
      <icon type="angle-right" class="right chevron icon"/>
    </Button>
  </div>
  <!--<div v-if="pdfdata && !limitedPreview" class="w-100 d-flex justify-content-between">
    <Button type="icon-button">
      <icon type="minus" class="left chevron icon" />
    </Button>
    <a class="mt-3">
      {{ formattedZoom }} %
    </a>
    <Button type="icon-button" @click="scale += scale < 2 ? 0.1 : 0">
      <icon type="plus" class="right chevron icon" />
    </Button>
  </div>-->
  <pdf v-if="pdfdata && !limitedPreview"
       class="pdf"
       :src="pdfdata"
       :resize="true"
       :page="page"
       :scale.sync="scale"
  />
  <div v-else class="square-image lighter">
    <icon type="pdf" size="2" />
  </div>
</div>
</template>

<script>
import pdf from 'pdfvuer';
import Icon from "../Icon";
import Button from "../../components/forms/Button";
export default {
  name: "PDFPreview",
  components: {
    pdf,
    Icon,
    Button
  },
  props: {
    /*
     * @previewData String can be URL or base46-data
     * **/
    previewData: {type: String, default: ''},
    limitedPreview: {type: Boolean, default: false},
  },
  data() {
    return {
      page: 1,
      numPages: 0,
      scale: 'page-width',
      buttonClass: 'hidden',
      pdfdata: '',
      pdf: null,
    };
  },
  computed: {
    formattedZoom () {
      return Number.parseInt(this.scale * 100);
    },
  },
  watch: {
    previewData() {
      this.getPdf();
    },
  },
  beforeMount() {
    this.getPdf();
  },
  methods: {
    getPdf () {
      if(!this.previewData) {
        return;
      }
      var self = this;
      self.pdfdata = pdf.createLoadingTask(this.previewData);
      self.pdfdata.then(pdf => {
        self.numPages = pdf.numPages;
        self.pdf = pdf;
      });
    },
    findPos(obj) {
      return obj.offsetTop;
    },
  }
}
</script>

<style lang="scss" scoped>

</style>